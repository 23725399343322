.pac-container {
    top: 285px;
    margin-top: 12px;
    min-width: 30%;
    border-radius: 8px;
    background-color: #ffffff;
    margin-left: -40px;
    border: 1px solid #CBD5E1;
}

.pac-item {
    height: 52px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    padding: 0 16px;
}

.pac-item>span:not(.pac-item-query) {
    text-overflow: ellipsis;
    overflow: hidden;
}

.pac-item,
.pac-matched {
    font-size: 16px;
    font-family: Ubuntu, sans-serif;
    color: #334155;
}

.pac-item:hover {
    font-weight: 700;
    color: #398378;
    background-color: #E8F6F4;
}

.pac-icon {
    display: none;
}