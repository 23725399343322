.rdp-text-overlay div {
    display: none
}

.rdp-column-container:nth-child(3) {
    display: none
}

.rdp-reticule {
    display: none
}

.rdp-cell {
    color: #334155;
    font-family: Ubuntu, sans-serif;
    /* Replace native library style */
    font-size: 24px !important;
}

.rdp-masked-div {
    width: 75%;
    mask-image: linear-gradient(transparent, 25%, rgba(0, 0, 0, 1), 70%, transparent);
    -webkit-mask-image: linear-gradient(transparent, 25%, rgba(0, 0, 0, 1), 70%, transparent);
}

.rdp-column-container {
    display: flex;
}

.rdp-column-container:first-child {
    display: flex;
    justify-content: flex-end;
}

.rdp-column-container:nth-child(2) {
    justify-content: flex-start;
    margin-top: -1px
}

.rdp-column-container:first-child .rdp-cell::after {
    content: ':00';
    color: #334155;
    font-size: 24px;
    font-family: Ubuntu, sans-serif;
}

.rdp-column-container:first-child::after {
    content: "";
    width: 50px;
    height: 100px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAADhCAMAAADlNIUbAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADAUExURf///+/y9tXd58vV4d/l7Pn6+9jg6ezv9PL099Ha5Njf6OXq8Ovv897l7Ojt8tLa5eLn7u/x9vz8/fj5+97k7Nvi6ujs8fz9/ens8vL1+M7Y487X4tXc5vv8/fLz9aastJmgqszQ1VlldjNBVaastb/EyUBNYLK4v/Lz9EBNX3N8iszP1FlkdEBMX2Zwf+bn6dnc4Obn6tnb301Yapmfqk1Za+Xn6mZwgH+Ilb/DyrK3v8/X49/k7O7y9fb3+eXp7wTM6rAAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAE7SURBVFhH7djbTsJAEAbg2tIDLRWl0KooUFcRrefz2fd/K2eb/2KHLCZQTDSZ76b/zCbdndtxxAo2XK/lI5t8jwQoTKE+8FCYIt1vo2BiOkiQuU4SITXlp8EmIuN36fItFKZt/VzbHAsH7LWpb/uVk/UHOWJTxU6yi8i5dLltwnqOPRSmxXMMqT9AwUT7w/gAWQghhPgXRuNJicgdKqWOkE3H1FdTFKYTfaBQmGan1D9DwVTnF5dXyE1dlzdI3IRee4tsKvVrl5qj0v07FMw99e1zzJYfb4TvnPFUPdgmfNSvekJhWmGOZzp4Qeaq1xmSEEII8ccU+M7J3zz3t5c173TwgcxF4bp2A5+B2+ohm+pljW0z8fOyxrb9qJc11qVTlqcdxKaKJA0ROT3HGpc1fRRMFne/ZFnjOM435zkV8nIS8/IAAAAASUVORK5CYII=) no-repeat center / contain;
    position: absolute;
    top: 0;
    left: 45%;
}
