.input-date-style {
    text-overflow: ellipsis;
    border-radius: 8px;
    border: 1px solid var(--Neutrals-Cool-gray-300, #CBD5E1);
    height: 56px;
    width: 100%;
    color: #94A3B8;
    padding: 0 40px;
    font-size: 16px;
    box-sizing: border-box;
}

.custom-calendar .basic-page-picker .rmdp-calendar {
    margin: 16px;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
}

.basic-page-picker~div>div>.rmdp-wrapper {
    margin: auto
}

.rmdp-container {
    width: 100%;
}

.ep-arrow {
    display: none;
}

.basic-page-picker.rmdp-wrapper {
    box-shadow: none;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
}

.custom-calendar {
    width: 100%;
    margin: auto
}

.ep-arrow~div {
    width: calc(100% - 64px);
}

.basic-page-picker .rmdp-calendar {
    margin: auto;
}

.basic-page-picker .rmdp-arrow {
    border-color: #94A3B8;
}

.basic-page-picker .rmdp-week-day {
    font-family: Ubuntu, sans-serif;
    color: #64748B
}

.basic-page-picker .rmdp-header-values span {
    padding: 0;
    font-weight: 700;
    font-family: Ubuntu, sans-serif;
}

.basic-page-picker .rmdp-day.rmdp-today span,
.basic-page-picker .rmdp-day.rmdp-range,
.basic-page-picker .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
    background-color: #E8F6F4;
    color: unset;
    border: 1px solid #D9EEEB;
    border-radius: 50%;
    font-family: Ubuntu, sans-serif;
}

/* Reset styles for date */
.basic-page-picker .rmdp-day.rmdp-range {
    background-color: unset;
    color: unset;
    border: none;
    box-sizing: border-box;
}

.basic-page-picker .rmdp-day span {
    bottom: 0;
    font-family: Ubuntu, sans-serif;
}

 .rmdp-day.rmdp-range span::after {
    content: '.';
    display: block;
    color: #398378;
    font-weight: 700;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.basic-page-picker .rmdp-calendar:focus {
    outline-color: #E8F6F4;
}

.basic-page-picker .rmdp-range {
    background-color: unset;
    box-shadow: none;
    color: unset;
}

.addTimingOpen:hover {
    background-color: #E8F6F4
}

.fc .fc-button-primary:hover {
    color: #398378;
}

.fc .fc-toolbar-title {
    min-width: 100px;
}
